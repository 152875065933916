import { CircularProgress, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import clsx from "clsx";
import { useAdminFrontendServicesClient } from "hooks/useHttpClient";
import React, { useState } from "react";
import { BranchGroupOption } from "WebApiClient";

interface IBranchesTableProps {
  OnSelect: (Branchgroup: BranchGroupOption | null) => void;
  AutoFocus: boolean;
  Value: BranchGroupOption | null;
  Selected: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    background: theme.palette.secondary.main
  },
  input: {
    color: "white"
  },
  endAdornment: {
    color: "white"
  },
  clearIndicatorDirty: {
    color: "white"
  },
  inputFocused: {
    color: "white"
  },
  selected: {
    background: theme.palette.primary.main,
    color: "white!important"
  }
}));
export const BranchGroupSelect: React.FC<IBranchesTableProps> = props => {
  const [isLoading, setIsLoading] = useState(false);
  const [branchGroupSelectOpen, setBranchGroupSelectOpen] = useState(false);
  const [errorOccured, setErrorOccured] = useState(true);
  const [branchgroups, setBranchGroups] = useState<BranchGroupOption[]>([]);
  const branchGroupClient = useAdminFrontendServicesClient();
  const theme = useStyles();
  function GetBranchgroupsAsync() {
    setIsLoading(true);
    branchGroupClient
      .getBranchGroupOptions()
      .then(response => {
        setBranchGroups(response);
      })
      .catch(() => {
        setErrorOccured(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }
  function OnBranchGroupSelect(branchgroup: BranchGroupOption | null) {
    props.OnSelect(branchgroup);
  }

  return (
    // <React.Fragment></React.Fragment>
    // <Select value={props.Value}><MenuItem selected={true}></MenuItem></Select>
    <Autocomplete
  open={branchGroupSelectOpen}
  onOpen={() => {
    setBranchGroupSelectOpen(true);
    GetBranchgroupsAsync();
  }}
  onClose={() => {
    setBranchGroupSelectOpen(false);
  }}
  getOptionSelected={(
    option: BranchGroupOption,
    value: BranchGroupOption
  ) => option.id === value.id}
  onChange={(event: any, value: BranchGroupOption | null) =>
    OnBranchGroupSelect(value)
  }
  getOptionLabel={(option: BranchGroupOption) =>
    `${option.label} (${option.branchCount})`
  }
  renderOption={(option: BranchGroupOption, state) => (
  <li style={{ display: 'flex', justifyContent: 'start', width: '100%',gap: '16px' }}>
    <span >{option.label}</span>
    <span style={{ fontWeight: 'bold', color: '#rgba(0, 0, 0, 0.87)'}}>{option.branchCount}</span>
  </li>
)}
  options={branchgroups}
  loading={isLoading}
  size="small"
  value={props.Value}
  className={clsx(theme.endAdornment, theme.clearIndicatorDirty)}
  renderInput={params => (
    <TextField
      {...params}
      label={`${props.Selected ? "" : "Branchgroup"}`}
      fullWidth
      variant="outlined"
      autoFocus={props.AutoFocus}
      className={props.Selected ? theme.selected : ""}
      InputProps={{
        ...params.InputProps,
        className: props.Selected
          ? clsx(
              theme.endAdornment,
              theme.clearIndicatorDirty,
              theme.selected
            )
          : "",
        endAdornment: (
          <div style={{ color: "white!important" }}>
            {isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : null}
            {params.InputProps.endAdornment}
          </div>
        )
      }}
    />
  )}
/>
  );
};

export default BranchGroupSelect;
